<template>
  <div class="job-recruitment-container container">
    <!-- 'hr'介绍 -->
    <h2 class="title h2_title">员工评语</h2>
    <div class="hr-list block">
      <el-carousel trigger="click" arrow="never">
        <el-carousel-item v-for="(item, i) in swiperList" :key="i">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="h_350">
              <img :src="item.img" alt="" class="swiper-img" />
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="hr-desc h_350">
              <h4>{{ item.name }}：</h4>
              <hr class="line" />
              <span v-html="item.desc"></span>
            </el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- '员工福利'区域 -->
    <h2 class="title h2_title">员工福利</h2>
    <div class="welfare">
      <img :src="welfareImg" alt="" class="welfare-img img-fluid" />
    </div>
    <!-- '岗位招聘'区域 -->
    <h2 class="title h2_title">岗位招聘</h2>
    <div class="job-wrap">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item :name="i" v-for="(item, i) in jobList" :key="i">
          <template slot="title">
            <div class="job-title">
              <div class="left-name">
                <h3 class="job-name">{{ item.name }}</h3>
                <h4 class="salary-desc">{{ item.postSalary }}</h4>
              </div>
              <div class="right-btn">
                <a href="javascript:void(0);" class="jump-button" @click.stop="onExternalLinkClick(item.url)">
                  Boss直聘链接
                </a>
              </div>
            </div>
          </template>
          <p class="job-desc-info">
            <b>工作内容：</b><br />
            <span v-for="(workContentItem, i2) in item.workContent" :key="`a${i2}`"> {{ `${i2 + 1}.${workContentItem}` }}<br /></span>

            <br />
            <b>岗位要求：</b><br />
            <span v-for="(jobRequirementsItem, i3) in item.jobRequirements" :key="`b${i3}`"> {{ `${i3 + 1}.${jobRequirementsItem}` }}<br /> </span>
          </p>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
// 轮播图图片
const swiperImg1 = require('../assets/home/experience_1.jpg')
const swiperImg2 = require('../assets/home/experience_2.jpg')
// '员工福利'图片
const welfareImg = require('../assets/home/fuli.png')
export default {
  name: 'JobRecruitment',
  data() {
    return {
      // 轮播图列表数据
      swiperList: [
        {
          img: swiperImg1,
          name: 'HR · Alisa',
          desc: `
          我们期待这样的你：<br />
          &nbsp;&nbsp;&nbsp;&nbsp;“勇于挑战，敢于创新，突破自我，在专业道路上不断精进；<br />
          &nbsp;&nbsp;&nbsp;&nbsp;富有责任心，结果导向，对每一份工作认真负责；<br /> 
          &nbsp;&nbsp;&nbsp;&nbsp;有协作精神，能与伙伴真诚沟通，共同学习成长。”`
        },
        {
          img: swiperImg2,
          name: 'HR · Sissie',
          desc: `
          &nbsp;&nbsp;&nbsp;&nbsp;“心有多大，舞台就有多大。以人为本，鼓励每个人的想法，主动积极、想到就做、勇于冒险、不怕犯错、无限去探讨可能性。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;强化执行能力，攻必克，战必胜，每个人都是公司的主人翁，有必胜的决心待人真诚，彼此包容，为人正直，懂得感恩，一起营造一个轻松快乐的大家庭。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;关注同事成长，和佰悦一起进步；关注用户，用户价值至上，提供公平公正的游戏环境。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;提倡敢想敢做，鼓励大胆创新，追求卓越。<br />
          &nbsp;&nbsp;&nbsp;&nbsp;每个人都是公司的主人翁，主动积极，寻求突破，每一件事情都能全力以赴。”`
        }
      ],
      // '员工福利'图片
      welfareImg,
      // 岗位列表展开集合
      activeNames: [],
      // 岗位列表数据
      jobList: [
        {
          name: '后端开发',
          workContent: ['负责手机游戏的服务端开发和优化', '负责平台项目的后台开发和维护'],
          jobRequirements: [
            '大专以上学历，熟悉Python/Golang语言，并理解最佳实践',
            '熟悉WebSocket/HTTP2，对web开发有深刻的理解',
            '熟练掌握并发编程，理解分布式系统和微服务设计',
            '具有良好的算法基础和计算机网络基础',
            '熟悉MySQL/MongoDB/Redis',
            '对代码质量有良好的意识，重视code review'
          ],
          url: 'https://www.zhipin.com/job_detail/ed9aeeb8bcb26df01nJ-29q9FVZW.html?ka=comp_joblist_6',
          postSalary: '武汉 | 岗位薪资：12-24K'
        },
        {
          name: 'Cocos creator',
          workContent: [
            '客户端框架的设计和游戏功能的实现，能独立设计开发各类游戏模块',
            '按照游戏设计完成游戏玩法逻辑开发、UI和动画的开发',
            '与产品、美术、后端讨论开发需求以及实现细节',
            '安卓和iOS的打包工作, 能独立完成各渠道的内购广告等SDK接入工作',
            '持续优化游戏性能，游戏逻辑以及用户体验, 解决崩溃闪退ANR等一系列问题'
          ],
          jobRequirements: [
            '大专及以上计算机相关专业学历，2年以上工作经验，具有移动游戏开发经验优先',
            '熟悉 JavaScript/TypeScript编程语言（Python/Shell可加分）',
            '熟悉常用的各类 SDK 接入流程，熟悉 Android、iOS 打包发布流程',
            '有兴趣，并且愿意往CocosCreator方向转的小伙伴优先',
            '熟悉常用的网络编程,熟悉HTML5框架与TCP/IP、WebSocket、HTTP等网络通信技术',
            '有规范的编程风格和良好文档习惯',
            '具有较强理解沟通能力、自学能力，热爱游戏，逻辑能力强，有较好的团队合作精神',
            '对技术始终充满热情,能够独立思考,分析问题,并解决问题'
          ],
          url: 'https://www.zhipin.com/job_detail/759b617521967f6e1nJ-29q7ElFR.html?ka=comp_joblist_8',
          postSalary: '武汉 | 岗位薪资：14-22K'
        },
        {
          name: '产品经理',
          workContent: [
            '负责游戏产品的市场调研、用户行为和需求分析、产品规划、产品需求文档等工作',
            '负责项目进度管理以及与开发、UX、测试、运营人员的沟通工作',
            '负责产品的推广和运营，对产品的各项运营指标(新增、留存、活跃、ARPU等关键指标)负责',
            '持续关注产品的用户体验及满意度,保障和优化产品'
          ],
          jobRequirements: [
            '大专及以上学历，2年以上游戏策划，游戏制作，游戏产品经理或者移动app工作经验，有海外项目经验优先',
            '熟悉使用各类产品设计工具软件，如VISIO 流程工具，axure 原型工具，MindManager或XMind等',
            '数据敏感度及逻辑能力强，有良好的业务理解能力、抽象概括能力，能够将业务问题产品化和模型化',
            '有平台级数据中心、支付中心、游戏渠道系统规划统筹、产品设计经验者优先',
            '具备良好的跨部门沟通协调能力，乐于接受挑战，执行能力强，有较强的团队精神'
          ],
          url: 'https://www.zhipin.com/job_detail/95e9da17b2f0d9e21nJ-29u0E1pX.html?ka=comp_joblist_11',
          postSalary: '武汉 | 岗位薪资：10-15K·13薪'
        },
        {
          name: '游戏测试',
          workContent: ['负责游戏功能模块的日常质量保障，对版本发布质量负责', '根据需求撰写测试用例，覆盖版本测试和日常测试和产品/开发做有效沟通，交流反馈碰到的问题，及时跟进线上bug'],
          jobRequirements: [
            '大专以上学历，有游戏测试经验，熟悉主流软件测试方法和工具',
            '熟悉CI以及相关研发配置管理工具，包括git/jenkins等',
            '熟悉TCP/IP，了解web开发的各种应用层协议',
            '有性能测试/压力测试/白盒测试经验优先',
            '熟悉Linux，掌握python/shell脚本语言优先'
          ],
          url: 'https://www.zhipin.com/job_detail/ad753fa806d187811nJ-29q8E1JR.html?ka=comp_joblist_3',
          postSalary: '武汉 | 岗位薪资：11-18K·13薪'
        },
        {
          name: '游戏UI',
          workContent: [
            '需要较强风格适应能力，能根据已有风格设计符合项目需求及策划文案需求的UI内容',
            '对UI及UE有自己的理解，对方案具有独立思考能力，并且能够推动方案落地实现并优化',
            '能够根据策划案制作出具有一定市场竞争力的界面',
            '能清晰熟练的进行界面设计，图标设计，资源出图，资源管理工作'
          ],
          jobRequirements: [
            '大专以上学历，1-2年以上游戏美术的项目经验和开发经验',
            '能够根据策划案独立进行界面制作，主动思考并推敲策划文档合理性，在设计过程中主动思考界面动效，并推动实现',
            '了解市面主流游戏的风格内容，具备手绘能力以及主流审美',
            '有优秀的游戏美术鉴赏能力，善于基于现有的优秀设计推陈出新',
            '具备独立优秀的工作能力及责任心，良好的沟通和理解能力，能有效的同团队其他环节人员协同工作，积极乐观的心态',
            '对海外休闲手机游戏的UI美术有一定项目经验'
          ],
          url: 'https://www.zhipin.com/job_detail/7e9666148c9b42021nJ70tW0F1VU.html?ka=comp_joblist_1',
          postSalary: '武汉 | 岗位薪资：10-15K·13薪'
        }
      ]
    }
  },
  methods: {
    // 处理函数,当岗位项的折叠状态发生变化时触发
    handleChange(val) {
      // 更新岗位列表展开集合
      this.activeNames = val
    },
    // 处理函数,当外部链接被点击时触发
    onExternalLinkClick(url) {
      // 在新窗口打开页面
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/common.scss';
.job-recruitment-container {
  padding-bottom: 10rem;

  // 'hr'介绍
  .hr-list {
    margin-top: 2rem;
    .h_350 {
      height: 350px;
    }
    .hr-desc {
      padding: 0 15px;
      .line {
        background-color: #ccc;
      }
    }
    ::v-deep.el-carousel__item.is-active {
      position: static !important;
    }
    ::v-deep.el-carousel__container {
      height: auto;
    }
  }
  // '我们的福利'区域
  .welfare {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    .welfare-img {
      margin: 0 auto;
    }
  }
  // '岗位招聘'区域
  ::v-deep.job-wrap {
    display: flex;
    flex-direction: column;
    .el-collapse-item__header {
      color: #fff;
      height: 5rem;
      // background-color: #c3a68e;
      // background-image: linear-gradient(170deg, #3a6186, #89253e);
      background-image: linear-gradient(167deg, #333333, #e9d362);
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .el-collapse-item__wrap {
      border: none;
    }
    .job-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 0.5rem;
      color: #efefef;
      .left-name {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 2rem;
      }
      .right-btn {
        .jump-button {
          position: relative;
          margin-right: 2rem;
          padding: 0.2rem 0.4rem;
          background-color: unset;
          color: #efefef;
          border: 0.15rem solid #efefef;
          user-select: none;
          font-size: 1rem;
          line-height: 1.5;
          border-radius: 0.25rem;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          &:hover {
            top: -0.2rem;
            color: rgba(251, 251, 222, 1);
            border-color: rgba(251, 251, 222, 1);
            box-shadow: 0 5px 10px rgba(251, 251, 222, 1);
          }
        }
      }
      h4.salary-desc {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-weight: bold;
        font-size: 1rem;
      }
    }
    .job-desc-info {
      padding: 1rem 0 1rem 2rem;
      background-color: #ddd;
    }
  }

  // 公共样式
  // 区域标题
  h2.title {
    margin-top: 2rem;
    // color: $themeColor;
  }
  // 轮播图样式
  .block {
    .h_350 {
      height: 350px;
    }
    // 轮播图图片
    .swiper-img {
      width: 100%;
      height: 100%;
    }
    // 轮播图指示点位置
    ::v-deep.el-carousel__indicators {
      bottom: 0;
    }
    // 轮播图指示点样式
    ::v-deep.el-carousel__button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgba($color: #000000, $alpha: 0.8);
    }
    // 轮播图指示点激活项样式
    ::v-deep.el-carousel__indicator.is-active button {
      background-color: #006cff;
    }
  }
}
// 轮播图
</style>
